<template>
  <div class="bg-red-100 grid gap-4 p-4" v-if="error_message">
    <div
      v-if="error_message"
      class="text-xs text-center text-red-600 "
    >
      {{ error_message }}
    </div>
    <ul v-if="errors" class="mx-auto max-w-xs">
      <li class="text-red-600 text-xs list-disc list-inside" v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["error_message", "errors"],
};
</script>
